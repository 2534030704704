import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from '@qga/roo-ui/components';
import ProcuredOfferOption from '../ProcuredOfferOption';
import Tile from '@/propTypes/Campaign';
import styled from '@emotion/styled';
import ErrorBoundary from '@/libs/v2/components/ErrorBoundary';

const StyledBox = styled(Box)`
  scroll-margin-top: 110px; //Size of sticky origin bar
`;

const renderTile = (tile, index) => {
  const tiles = {
    CROSS_SELL_BANNER: null,
    FEATURED_PROPERTY: null,
    PROCURED_OFFER: (
      <StyledBox
        width="100%"
        key={tile.id}
        py={5}
        data-testid="PROPERTY_CARD"
        id={tile.id}
      >
        <ProcuredOfferOption tile={tile} index={index} showHotelTerms />
      </StyledBox>
    ),
  };

  return tiles[tile.type];
};

const CardListing = ({ tiles }) => (
  <ErrorBoundary>
    <Flex flexDirection={['column', null, 'row']} flexWrap="wrap">
      {tiles.map((tile, index) => renderTile(tile, index))}
    </Flex>
  </ErrorBoundary>
);

CardListing.propTypes = {
  tiles: PropTypes.arrayOf(PropTypes.shape(Tile)).isRequired,
};

export default CardListing;
