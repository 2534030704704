import React from 'react';
import { Badge, Box } from '@qga/roo-ui/components';
import sortImages from '@/utils/sortImages';
import getProcuredOfferSash from '@/utils/getProcuredOfferSash';
import ImageGallery from '@/shared/components/ImageGallery';
import PropTypes from 'prop-types';
import Tile from '@/propTypes/Campaign';

const ArticleGallery = ({ tile }) => {
  const { text, icon } = getProcuredOfferSash(tile.procuredOffer);

  return (
    <Box width={['100%', null, '728px']} position="relative">
      <ImageGallery
        images={sortImages(tile.images, tile.mainImage)}
        height={['240px', null, '450px']}
        altText={tile.name}
      />
      <Badge
        iconName={icon}
        text={text}
        type={'warning'}
        borderRadius="0 0 10px 0"
        position="absolute"
        top={0}
        left={0}
        fontWeight="bold"
        data-testid="OFFER_SASH"
      />
    </Box>
  );
};

ArticleGallery.propTypes = {
  tile: PropTypes.shape(Tile).isRequired,
};

export default ArticleGallery;
