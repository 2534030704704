import React from 'react';
import Router from 'next/router';
import getTravellersDescription from '@/utils/getTravellersDescription';
import pluralize from 'pluralize';
import { Icon } from '@qga/roo-ui/components';
import getOfferCallToAction from '../getOfferCallToAction';
import PaymentBlock from '@/components/ExclusiveOffersPage/components/PaymentBlock';
import BundleFooter from '@/components/ExclusiveOffersPage/components/BundleFooter';
import dataLayer from '../../../../../../../../../libs/shared/utils/dataLayer';
import userInteractionEvent from '../../../../../../../../../libs/shared/utils/userInteractionEvent';
import {
  viewOfferLinkDataLayerEvent,
  viewOfferPopupDataLayerEvent,
} from '@/components/ExclusiveOffersPage/datalayer';
import v2dataLayer from '@/v2/utils/dataLayer';

const FLIGHTS_HOTEL_INCLUSIONS_TITLE = 'FLIGHTS + HOTEL + INCLUSIONS';
const HOTEL_INCLUSIONS_TITLE = 'HOTEL + INCLUSIONS';
const EXCLUSIVE_OFFERS_PAGE = 'deals';

const getBundleProps = (tile, isHolidaysBundle, builderOptions = {}) => {
  const { minNumberOfNights, travellers, displayTravellerTypes } =
    tile.procuredOffer.rooms[0].leadInOffers[0];

  const numberOfGuests = getTravellersDescription(
    travellers,
    displayTravellerTypes,
    isHolidaysBundle ? 'traveller' : 'guest',
  );

  const {
    setShowAllOptionsModal,
    isNewPackagePageEnabled,
    packageOptionId,
    hasMultiplePackageOption,
    useNewAnalytics,
    pageName,
    originCode,
    selectItemEventData,
  } = builderOptions || {};

  const nightsStay =
    pluralize('night', minNumberOfNights, true) + ' stay from:';

  return {
    onClick: () => {
      const pathname = getOfferCallToAction({
        tile,
        isHolidaysBundle,
      });

      if (useNewAnalytics) {
        if (pageName) {
          if (hasMultiplePackageOption && isHolidaysBundle) {
            viewOfferPopupDataLayerEvent({ pageName });
            v2dataLayer.ctaClickEvent({
              itemText: 'View offer',
            });
          } else {
            viewOfferLinkDataLayerEvent({
              pageName,
              bundleVariant: isHolidaysBundle ? 'Holidays' : 'Hotel',
            });
            if (!!selectItemEventData) {
              v2dataLayer.selectItemEvent(selectItemEventData);
              v2dataLayer.ctaClickEvent({
                itemText: 'View offer',
                url: pathname,
              });
            }
          }
        }
      } else {
        dataLayer.push(
          userInteractionEvent(
            Router.asPath === `/${EXCLUSIVE_OFFERS_PAGE}`
              ? 'Exclusive Offers'
              : 'Home',
            `View Offer - ${isHolidaysBundle ? 'Holidays' : 'Hotel'}`,
            'Offer Card',
          ),
        );
      }

      if (isHolidaysBundle) {
        hasMultiplePackageOption
          ? setShowAllOptionsModal(true)
          : Router.push({
              pathname,
              query: isNewPackagePageEnabled
                ? {
                    packageOption: packageOptionId,
                    originCode,
                  }
                : { originCode },
            });
      } else {
        window.open(
          builderOptions.usePoints ? `${pathname}?payWith=points` : pathname,
          '_blank',
        );
      }
    },
    isCompleteBundle: true,
    title: isHolidaysBundle
      ? FLIGHTS_HOTEL_INCLUSIONS_TITLE
      : HOTEL_INCLUSIONS_TITLE,
    titleIcon: isHolidaysBundle && (
      <Icon name="flightRight" mr={2} size="1.2rem" />
    ),
    ...(isHolidaysBundle ? { badgeProps: { text: 'Bundle & Save' } } : {}),
    buttonText: 'View Offer',
    variant: 'primary',
    subtext: [numberOfGuests, nightsStay],
    bodyComponent: (
      <PaymentBlock tile={tile} isHolidaysBundle={isHolidaysBundle} />
    ),
    footerComponent: !builderOptions.usePoints ? (
      <BundleFooter isHolidaysBundle={isHolidaysBundle} />
    ) : null,
  };
};

export default getBundleProps;
