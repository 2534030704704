import getPrimaryOrigin from '@/utils/getPrimaryOrigin';
import format, { MEDIUM_DATE_FORMAT } from '@/lib/date/format';
import { ORIGINS } from '@/constants';
import pluralize from 'pluralize';
import useSearchQuery from '@/hooks/useSearchQuery';
import {
  CampaignTile,
  Dates,
  ProcuredOffer,
  PropertyContent,
} from '@/v2/services/sanity/types/api/response';

const getOfferEnds = (saleDateRange: Dates) => {
  return format(MEDIUM_DATE_FORMAT)(saleDateRange.end.local);
};

const getTravelPeriod = (travelDateRange: Dates) => {
  const travelStart = format(MEDIUM_DATE_FORMAT)(travelDateRange.start.local);
  const travelEnd = format(MEDIUM_DATE_FORMAT)(travelDateRange.end.local);

  return `${travelStart} - ${travelEnd}.`;
};

const useIncludedFlights = (propertyContent: PropertyContent) => {
  const {
    destination: { origins },
  } = propertyContent;

  const primaryOrigin = getPrimaryOrigin(origins);
  const { searchQuery } = useSearchQuery();
  const departingOriginName =
    searchQuery?.originCode &&
    ORIGINS.find((O) => O.code === searchQuery.originCode)?.name;

  return departingOriginName || primaryOrigin?.name;
};

const getMinStay = (procuredOffer: ProcuredOffer) => {
  const {
    leadInOffers: [{ minNumberOfNights }],
  } = procuredOffer.rooms[0];

  return pluralize('night', minNumberOfNights, true);
};

const getRoomName = (procuredOffer: ProcuredOffer) => {
  return procuredOffer.rooms[0].room.name;
};

const useTermsAndConditions = (tile: CampaignTile) => {
  const { procuredOffer, propertyContent } = tile;
  const departingLocation = useIncludedFlights(propertyContent);

  if (!procuredOffer) {
    return { terms: undefined };
  }

  // handle undefined procuredOffer
  const { saleDateRange, travelDateRange, brands } = procuredOffer;

  const offerEnds = getOfferEnds(saleDateRange);
  const minStay = getMinStay(procuredOffer);
  const travelPeriod = getTravelPeriod(travelDateRange);
  const roomName = getRoomName(procuredOffer!);

  const flightsAndHotelsDisclaimer = `
    Offer ends: ${offerEnds}.
    Travel period applies.
    Flight + Hotel package includes return Economy Qantas flights departing ${departingLocation}. Subject to availability.
    Min stay: ${minStay}.`;

  const hotelsDisclaimer = `
    Offer ends: ${offerEnds}.
    Travel period: ${travelPeriod}
    Price displayed is for ${minStay} in a ${roomName}.`;

  const terms = brands.includes('qantas-holidays')
    ? flightsAndHotelsDisclaimer
    : hotelsDisclaimer;

  return { terms };
};

export default useTermsAndConditions;
