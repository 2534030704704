import React from 'react';
import { Box, Text } from '@qga/roo-ui/components';
import Modal, { ModalProps } from '@/v2/components/ui/Modal/Modal';
import Divider from '@/v2/components/ui/Divider';
import TermsAndConditions from './components/TermsAndConditions';
import ExclusiveInclusions from './components/ExclusiveInclusions';
import { CampaignTile } from '@/v2/services/sanity/types/api/parsedResponse';

interface Props extends Pick<ModalProps, 'onClose'> {
  tile: CampaignTile;
}

const ProcuredOfferModal = ({ onClose, tile }: Props) => {
  const { procuredOffer, name } = tile;

  if (!procuredOffer) {
    return null;
  }

  const { description, terms, hotelTerms, brands = [] } = procuredOffer;
  const { inclusions } = procuredOffer.rooms[0].leadInOffers[0];

  const hasHolidaysTermsAndConditions =
    brands.includes('qantas-holidays') && terms;
  const hasHotelsTermsAndConditions =
    brands.includes('qantas-hotels') && hotelTerms;

  return (
    <Modal onClose={onClose}>
      <Modal.Header>Terms & Conditions</Modal.Header>
      <Modal.Body>
        <Box p={5}>
          <Box fontSize="lg" mb={4}>
            {name}
          </Box>
          <Box fontSize="base">{description}</Box>
        </Box>
        <Divider mx={5} />
        {inclusions?.length > 0 && (
          <>
            <Box p={5}>
              <Box mb={3}>
                <Text fontSize="base">Exclusive inclusions:</Text>
              </Box>
              <ExclusiveInclusions inclusions={inclusions} showSubtitle />
            </Box>
            <Divider mx={5} />
          </>
        )}
        {hasHolidaysTermsAndConditions && (
          <Box p={5} pt={3}>
            <TermsAndConditions
              label="Holidays terms & conditions"
              termsAndConditions={terms}
            />
          </Box>
        )}
        {hasHolidaysTermsAndConditions && hasHotelsTermsAndConditions && (
          <Divider mx={5} />
        )}
        {hasHotelsTermsAndConditions && (
          <Box p={5} pt={3}>
            <TermsAndConditions
              label="Hotels terms & conditions"
              termsAndConditions={hotelTerms}
            />
          </Box>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ProcuredOfferModal;
