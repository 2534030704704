import React from 'react';
import sortBundles from '../sortBundles';
import getBundle from '../getBundle';

const bundleBuilder = (tile, bundleOptions) => {
  const hotelBundle = getBundle(tile, 'qantas-hotels', bundleOptions);
  const holidaysBundle = getBundle(tile, 'qantas-holidays', bundleOptions);

  const commonProps = {
    titleFontSize: 16,
  };

  const defaultData = [hotelBundle, holidaysBundle].map(
    ({ placeholder, ...data }) => {
      return !data.isCompleteBundle
        ? {
            ...commonProps,
            title: data.title,
            titleIcon: data.titleIcon,
            placeholder,
          }
        : { ...commonProps, ...data };
    },
  );

  return {
    bundles: defaultData.sort(sortBundles),
    bundleAndSaveExists: !!defaultData.find((data) => data.badgeProps),
    hasMultipleCompleteBundles:
      defaultData.filter((data) => data.isCompleteBundle).length > 1,
  };
};

export default bundleBuilder;
