import React from 'react';
import PropTypes from 'prop-types';
import { Box, NakedButton, Text } from '@qga/roo-ui/components';
import Tile from '@/propTypes/Campaign';
import ToggleContent from '@experiences-ui/shared/v2/components/ToggleContent';
import useTermsAndConditions from './utils/termsAndConditions';
import ProcuredOfferModal from '@/v2/components/ui/ProcuredOfferModal';
import Router from 'next/router';
import userInteractionEvent from '@experiences-ui/shared/utils/userInteractionEvent';
import getPageNameFromPath from '@experiences-ui/shared/utils/getPageNameFromPath';
import dataLayer from '../../../../../../../libs/shared/utils/dataLayer';

const ArticleAnnotation = ({ tile }) => {
  const { terms } = useTermsAndConditions(tile);
  return (
    <Box color="greys.steel" fontSize="xs" mx={6} mt={4}>
      {terms && (
        <Text fontSize="xs" data-testid="terms">
          {terms}{' '}
        </Text>
      )}
      <ToggleContent
        toggle={(open) => (
          <NakedButton
            textDecoration="underline"
            onClick={() => {
              dataLayer.push(
                userInteractionEvent(
                  getPageNameFromPath(Router.asPath),
                  `Conditions Apply Link Selected`,
                  'Conditions Apply',
                ),
              );
              open();
            }}
          >
            Conditions apply
          </NakedButton>
        )}
        content={(close) => (
          <ProcuredOfferModal onClose={close} tile={tile} showHotelTerms />
        )}
      />
    </Box>
  );
};

ArticleAnnotation.propTypes = {
  tile: PropTypes.shape(Tile).isRequired,
};

export default ArticleAnnotation;
