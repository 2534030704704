import { Box, Text } from '@qga/roo-ui/components';
import PropTypes from 'prop-types';

const PaymentBlockSubtext = ({ valuation }) => {
  return !!valuation ? (
    <Box
      fontSize="sm"
      color="greys.steel"
      data-testid={'payment-block-subtext'}
    >
      <Text>Valued at ${Math.ceil(valuation?.amount).toLocaleString()}</Text>
    </Box>
  ) : null;
};

PaymentBlockSubtext.propTypes = {
  valuation: PropTypes.shape({
    amount: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.oneOf([null]),
    ]),
  }),
};

export default PaymentBlockSubtext;
