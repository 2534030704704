import React from 'react';
import { Flex, Text } from '@qga/roo-ui/components';

// @ts-ignore
import Inclusion from '@/components/Inclusion';
import { Inclusion as InclusionType } from '@/v2/services/sanity/types/api/response';

interface Props {
  inclusions: InclusionType[];
  variant?: 'base';
  showSubtitle: boolean;
  truncateInclusions?: number;
}

const ExclusiveInclusions = ({
  inclusions,
  variant,
  showSubtitle,
  truncateInclusions,
}: Props) => {
  const mismatchLengths = inclusions.length != truncateInclusions;
  const truncationText = ' and more...';

  return (
    <>
      {inclusions
        .slice(0, truncateInclusions)
        .map(({ code, name, subtitle }, index) => {
          const isLastInclusion =
            truncateInclusions && index === truncateInclusions - 1;
          const shouldShowTruncateText = mismatchLengths && isLastInclusion;

          return (
            <Flex key={`${code}-${index}`} mb="2">
              <Inclusion code={code} />
              <Flex flexDirection="column" ml="4">
                <Text fontSize={variant === 'base' ? 'base' : 'sm'}>
                  {name}
                  {shouldShowTruncateText && truncationText}
                </Text>
                {showSubtitle && subtitle && (
                  <Text
                    fontSize={variant === 'base' ? 'sm' : '2xs'}
                    color="greys.steel"
                  >
                    {subtitle}
                  </Text>
                )}
              </Flex>
            </Flex>
          );
        })}
    </>
  );
};

export default ExclusiveInclusions;
