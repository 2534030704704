import React from 'react';
import { Flex, Text } from '@qga/roo-ui/components';
import PropTypes from 'prop-types';

const DisplayAmount = ({ amount = '' }) => {
  return (
    <Text
      fontSize={['2xl', null, '2xl']}
      fontWeight={'bold'}
      lineHeight={1}
      pr={1}
    >
      {Math.ceil(amount).toLocaleString()}
    </Text>
  );
};

const DisplayShortcode = ({ shortcode }) => {
  return (
    <Text fontSize={'sm'} fontWeight={400}>
      {shortcode}
    </Text>
  );
};

const PaymentBlockText = ({ isPoints, amount, shortcode }) => {
  if (!amount) return null;

  const inlineShortcode = isPoints
    ? shortcode.concat('^')
    : shortcode.concat('*');
  return (
    <Flex
      data-testid="payment-block-text"
      alignItems={'center'}
      color={'greys.charcoal'}
    >
      {!isPoints && (
        <Text fontWeight={'bold'} alignSelf={'flex-start'}>
          $
        </Text>
      )}
      <DisplayAmount amount={amount} />{' '}
      <DisplayShortcode shortcode={inlineShortcode} />
    </Flex>
  );
};

PaymentBlockText.propTypes = {
  isPoints: PropTypes.bool.isRequired,
  amount: PropTypes.number.isRequired,
  shortcode: PropTypes.string.isRequired,
};

export default PaymentBlockText;
