import React from 'react';
import { Flex, Heading } from '@qga/roo-ui/components';
import PropTypes from 'prop-types';
import Tile from '@/propTypes/Campaign';
import PaymentBlockText from '../PaymentBlockText';
import usePaymentBlock from '@/hooks/usePaymentBlock';
import SavingsCvp from '@/components/SavingsCvp';
import PaymentBlockSubtext from '../PaymentBlockSubtext';

const PaymentBlock = ({ tile, isHolidaysBundle }) => {
  const { usePoints, points, total, valuedAtTotal } = usePaymentBlock(
    tile,
    isHolidaysBundle,
  );

  return (
    <Flex data-testid="payment-block" flexDirection="column" gap={0.5}>
      {total ? (
        <>
          <PaymentBlockText
            isPoints={usePoints}
            amount={usePoints ? points : total?.amount}
            shortcode={usePoints ? 'PTS' : total?.currency}
          />
          {!usePoints && <PaymentBlockSubtext valuation={valuedAtTotal} />}
          {!usePoints && <SavingsCvp total={total} valuation={valuedAtTotal} />}
        </>
      ) : (
        <Heading.h6 fontSize="16px" lineHeight="24px" color="brand.primary">
          Check availability for package pricing
        </Heading.h6>
      )}
    </Flex>
  );
};

PaymentBlock.propTypes = {
  tile: PropTypes.shape(Tile).isRequired,
};

export default PaymentBlock;
