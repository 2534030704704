import React from 'react';
import { Text } from '@qga/roo-ui/components';
import getBundleProps from '../getBundleProps';

export const FLIGHTS_HOTEL_INCLUSIONS_TITLE = 'FLIGHTS + HOTEL + INCLUSIONS';
const HOTEL_INCLUSIONS_TITLE = 'HOTEL + INCLUSIONS';

const getBundle = (tile, bundle, bundleOptions) => {
  const isHolidaysBundle = bundle === 'qantas-holidays';
  const bundleExists = tile.procuredOffer?.brands?.includes(bundle);
  const bundleTitle = isHolidaysBundle
    ? FLIGHTS_HOTEL_INCLUSIONS_TITLE
    : HOTEL_INCLUSIONS_TITLE;

  const originCode = bundleOptions?.originCode;

  const bundlePlaceholder = isHolidaysBundle
    ? 'This offer is available as a hotel package only'
    : 'This offer is available as a flights + hotel bundle only';

  const origins = tile?.propertyContent?.destination?.origins;
  const routeExists = !!origins
    ? origins.map((o) => o.code).includes(originCode)
    : false;

  if (!bundleExists || (isHolidaysBundle && !!originCode && !routeExists)) {
    return {
      title: bundleTitle,
      isCompleteBundle: false,
      placeholder: <Text>{bundlePlaceholder}</Text>,
    };
  }

  const tileInfo = tile?.procuredOffer.rooms[0].leadInOffers[0];
  if (!tileInfo) return null;

  return {
    title: bundleTitle,
    ...getBundleProps(tile, isHolidaysBundle, bundleOptions),
  };
};

export default getBundle;
