import React from 'react';
import { Box, Flex, Icon, Text } from '@qga/roo-ui/components';
import PointsPerDollar from '../../helpers/PointsPerDollar/PointsPerDollar';

const BundleFooter = ({ isHolidaysBundle }) => {
  return (
    <Flex>
      <Box mt="1">
        <Icon name="roo" color="brand.primary" />
      </Box>
      <Flex
        ml="3"
        flexDirection={['column', null, 'row']}
        alignItems={['flex-start', null, 'center']}
        alignSelf={'center'}
        data-testid="bundle-footer-text"
      >
        <Box fontSize="xs" color="greys.charcoal" mr={1}>
          <PointsPerDollar isHolidaysBundle={isHolidaysBundle} />
        </Box>
        {isHolidaysBundle && (
          <Box>
            <Text fontSize="xs" color="greys.steel">
              Plus Status Credits and points on flights
            </Text>
          </Box>
        )}
      </Flex>
    </Flex>
  );
};

export default BundleFooter;
