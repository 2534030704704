import React from 'react';
import { useAppSettings } from '@/components/AppSettingsProvider';
import { useApp } from '@/v2/contexts/app';

const PointsPerDollar = ({ isHolidaysBundle }) => {
  const appSettings = useAppSettings();
  const app = useApp();
  const pointsPerDollar = appSettings?.pointsPerDollar || app?.pointsPerDollar;
  const hotelsPointsPerDollar =
    appSettings?.hotelsPointsPerDollar || app?.hotelsPointsPerDollar;

  return (
    <>
      Earn{' '}
      <strong>
        {isHolidaysBundle ? pointsPerDollar : hotelsPointsPerDollar} PTS
      </strong>{' '}
      per A$1 spent^
    </>
  );
};

export default PointsPerDollar;
