import type { UI } from '@/v2/types';
import type { ReactNode } from 'react';
import { isAfter, isBefore } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { useRouter } from 'next/router';

import { Box } from '@qga/roo-ui/components';
import SiteMessage from '@/v2/components/ui/SiteMessage/SiteMessage';
import { AppProvider } from '@/v2/contexts/app';
import { NavigationProvider } from '@/v2/contexts/navigation';
import SiteFooter from '@/v2/components/ui/SiteFooter';

// Legacy components
import SiteHeader from '../../../../components/Header';
import SiteNav from '../../../../components/PageHeader';
import MaintenanceView from '../../../../components/MaintenanceView';

export interface Props {
  app: UI.Settings;
  children: ReactNode;
  navigation: Array<UI.Navigation>;
}

const Layout = ({ children, app, navigation }: Props) => {
  const router = useRouter();
  const isContactUsPage = router.asPath.includes('contact-us');

  let showMaintenanceMessage = false;
  if (app.maintenanceMessage?.enabled) {
    const { start, end } = app.maintenanceMessage;
    const now = zonedTimeToUtc(new Date(), start.timezone);
    showMaintenanceMessage =
      isAfter(now, zonedTimeToUtc(start.utc, start.timezone)) &&
      (!end || isBefore(now, zonedTimeToUtc(end.utc, end.timezone))) &&
      !isContactUsPage;
  }

  return (
    <AppProvider value={app}>
      <NavigationProvider value={navigation}>
        <Box bg="greys.porcelain">
          <SiteHeader />
          <SiteNav />
          <SiteMessage siteMessage={app.campaignMessage} variant="info" />
          <main>
            {showMaintenanceMessage ? (
              <MaintenanceView maintenanceMessage={app.maintenanceMessage} />
            ) : (
              children
            )}
          </main>
          <SiteFooter />
        </Box>
      </NavigationProvider>
    </AppProvider>
  );
};

export default Layout;
