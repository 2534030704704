import { LeadInOffer } from '@/v2/services/sanity/types/api/parsedResponse';

const defaultHotelsLeadInPrice = {
  total: { amount: undefined, currency: undefined },
  valuedAtTotal: { amount: undefined, currency: undefined },
};

const getHotelsLeadInPrice = (
  leadInOffer: Pick<LeadInOffer, 'staysOnlyOffer'>,
) => {
  if (!leadInOffer?.staysOnlyOffer) {
    return defaultHotelsLeadInPrice;
  }

  return {
    total: {
      amount: parseFloat(`${leadInOffer.staysOnlyOffer.offerTotal?.amount}`),
      currency: leadInOffer.staysOnlyOffer.offerTotal.currency,
    },
    valuedAtTotal: leadInOffer.staysOnlyOffer.valuedAtTotal
      ? {
          amount: parseFloat(
            `${leadInOffer.staysOnlyOffer.valuedAtTotal?.amount}`,
          ),
          currency: leadInOffer.staysOnlyOffer.valuedAtTotal?.currency,
        }
      : defaultHotelsLeadInPrice,
  };
};

export default getHotelsLeadInPrice;
