import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Box, Flex, Heading, Hide, Icon, Text } from '@qga/roo-ui/components';
import Destination from '@/components/Destination';
import LinkButton from '@/components/LinkButton';
import useBreakpoints from '@/hooks/useBreakpoints';
import DestinationPropType from '@/propTypes/Destination';
import ErrorBoundary from '@/libs/v2/components/ErrorBoundary';

const Destinations = ({ destinations, heading }) => {
  const breakpoints = useBreakpoints();
  const [viewAllDestinations, setViewAllDestinations] = useState(false);

  return (
    <ErrorBoundary>
      <Heading.h2
        fontWeight="normal"
        mb={['4', null, '9']}
        fontSize={['lg', null, 'xl']}
        textAlign="center"
      >
        {heading}
      </Heading.h2>
      <Flex flexWrap="wrap" justifyContent="space-between">
        {destinations.map(
          ({ name, title, image, comingSoon, properties }, i) => {
            const { xs, sm } = breakpoints;
            const show = xs || sm ? i < 4 : true;

            return (
              (show || viewAllDestinations) && (
                <Box
                  key={name}
                  width={['100%', null, 'calc(100%/4 - 8px)']}
                  my="2"
                >
                  <Destination
                    name={name}
                    title={title}
                    image={image}
                    comingSoon={comingSoon}
                    count={properties.length}
                    index={i}
                    heading={heading}
                  />
                </Box>
              )
            );
          },
        )}
        {[...Array(4 - (destinations.length % 4)).keys()].map((i) => (
          <Box
            key={i}
            data-testid="PLACEHOLDER"
            width={['100%', null, 'calc(100%/4 - 8px)']}
            my={[null, null, '2']}
          />
        ))}
      </Flex>
      {destinations.length > 4 && (
        <Hide md lg>
          <Box textAlign="center">
            <LinkButton onClick={() => setViewAllDestinations((v) => !v)}>
              <Flex alignItems="center">
                {viewAllDestinations ? (
                  <>
                    <Text fontWeight="bold">SHOW LESS</Text>
                    <Icon name="expandLess" />
                  </>
                ) : (
                  <>
                    <Text fontWeight="bold">VIEW MORE</Text>
                    <Icon name="expandMore" />
                  </>
                )}
              </Flex>
            </LinkButton>
          </Box>
        </Hide>
      )}
    </ErrorBoundary>
  );
};

Destinations.propTypes = {
  destinations: PropTypes.arrayOf(PropTypes.shape(DestinationPropType))
    .isRequired,
  heading: PropTypes.string.isRequired,
};

export default Destinations;
