const getOfferCallToAction = ({ tile, isHolidaysBundle }) => {
  if (!tile) return null;

  const { propertyContent } = tile;
  const { id: propertyId, destination } = propertyContent;
  const { name: destinationName } = destination;

  return isHolidaysBundle
    ? `/${destinationName}/properties/${propertyId}/package`
    : `/hotels/properties/${propertyId}/exclusive-offers`;
};

export default getOfferCallToAction;
