import React from 'react';
import { Box, Text } from '@qga/roo-ui/components';
import Highlights from '@/components/ProcuredOfferOption/components/Highlights';
import ProcuredOfferModal from '@/v2/components/ui/ProcuredOfferModal';
import ToggleContent from '@experiences-ui/shared/v2/components/ToggleContent';
import UnderlinedNakedButton from '@/components/UnderlinedNakedButton';
import PropTypes from 'prop-types';
import Tile from '@/propTypes/Campaign';
import Router from 'next/router';
import userInteractionEvent from '@experiences-ui/shared/utils/userInteractionEvent';
import getPageNameFromPath from '@experiences-ui/shared/utils/getPageNameFromPath';
import dataLayer from '../../../../../../../libs/shared/utils/dataLayer';
import { useDataLayerContext } from '@experiences-ui/shared/v2/contexts/DataLayerContext';
import { usePackagePageV2Analytics } from '@/v2/hooks/featureFlags/packagePageV2Analytics/usePackagePageV2Analytics';
import { viewDetailsDataLayerEvent } from '@/components/ExclusiveOffersPage/datalayer';

const ArticleBody = ({ tile }) => {
  const { inclusions } = tile.procuredOffer.rooms[0].leadInOffers[0];
  const { pageName } = useDataLayerContext() || {};
  const shouldUseNewAnalytics = usePackagePageV2Analytics();

  return (
    <Box position="relative">
      {inclusions?.length > 0 && (
        <>
          <Text fontSize="sm" mb="3" display="inline-block">
            Exclusive inclusions:
          </Text>
          <Highlights
            inclusions={inclusions}
            truncateFourthInclusion={inclusions?.length > 4}
          />
        </>
      )}
      <ToggleContent
        toggle={(open) => (
          <UnderlinedNakedButton
            fontSize="sm"
            onClick={() => {
              if (shouldUseNewAnalytics) {
                if (pageName) {
                  viewDetailsDataLayerEvent({ pageName });
                }
              } else {
                dataLayer.push(
                  userInteractionEvent(
                    getPageNameFromPath(Router.asPath),
                    `View Details Link Selected`,
                    'View Details',
                  ),
                );
              }
              open();
            }}
            mt={3}
          >
            View details
          </UnderlinedNakedButton>
        )}
        content={(close) => (
          <ProcuredOfferModal onClose={close} tile={tile} showHotelTerms />
        )}
      />
    </Box>
  );
};

ArticleBody.propTypes = {
  tile: PropTypes.shape(Tile).isRequired,
};

export default ArticleBody;
