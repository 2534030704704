import { Box, Text } from '@qga/roo-ui/components';
import React from 'react';
import ConditionsMarkdown from '@/v2/../components/ConditionsMarkdown';

const TermsAndConditions = ({
  termsAndConditions,
  label,
}: {
  label?: string;
  termsAndConditions?: string;
}) => {
  if (!termsAndConditions) {
    return null;
  }

  return (
    <>
      <Text fontSize="md" fontWeight="bold">
        {label}
      </Text>
      <Box fontSize="base">
        <ConditionsMarkdown markdown={termsAndConditions} />
      </Box>
    </>
  );
};

export default TermsAndConditions;
