import React from 'react';
import * as Sentry from '@sentry/nextjs';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';

function FallbackComponent() {
  return null;
}

const ErrorBoundary = ({ children }: { children: React.ReactElement }) => {
  const handleError = (error: Error, info: { componentStack: string }) => {
    // Fatal errors will automatically notify Slack (Sentry Alert rule)
    Sentry.captureMessage(
      `Component has thrown an error! ${info.componentStack}`,
      'fatal',
    );
  };

  return (
    <ReactErrorBoundary fallback={<FallbackComponent />} onError={handleError}>
      {children}
    </ReactErrorBoundary>
  );
};

export default ErrorBoundary;
