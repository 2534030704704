import type { ReactNode } from 'react';
import { Box } from '@qga/roo-ui/components';

interface Props {
  children: ReactNode;
}

const Page = ({ children, ...rest }: Props) => {
  return (
    <Box mx="auto" bg="white" width="100%" maxWidth="1600px" {...rest}>
      {children}
    </Box>
  );
};

export default Page;
