import { UI } from '@/v2/types';
import Head from 'next/head';

import translations from '@/v2/locales/en/common.json';

export interface Props extends UI.Meta {}

const Meta = ({ description, title }: Props) => {
  return (
    <Head>
      <title>
        {title} | {translations.app.title}
      </title>
      <meta name="description" content={description} />
    </Head>
  );
};

export default Meta;
