import dataLayer from '@/v2/utils/dataLayer/dataLayer';

export const viewOfferPopupDataLayerEvent = ({
  pageName,
}: {
  pageName: string;
}) =>
  dataLayer.userInteractionsEvent({
    label: pageName,
    action: `${pageName} popup appeared`,
    category: 'Offer Card',
  });

export const viewOfferLinkDataLayerEvent = ({
  pageName,
  bundleVariant,
}: {
  pageName: string;
  bundleVariant: 'Holidays' | 'Hotel';
}) =>
  dataLayer.userInteractionsEvent({
    label: pageName,
    action: `View Offer - ${bundleVariant}`,
    category: 'Offer Card',
  });

export const viewDetailsDataLayerEvent = ({ pageName }: { pageName: string }) =>
  dataLayer.userInteractionsEvent({
    label: pageName,
    action: 'Deal details and terms',
    category: 'CTA Click',
  });
