import React from 'react';
import { Bundle, BundleGroup } from '@qga/roo-ui/components';
import PropTypes from 'prop-types';

const ArticleFooter = ({ bundles, bundleAndSaveExists }) => (
  <BundleGroup alignBodyContent={bundleAndSaveExists}>
    {bundles.map((data, index) => (
      <Bundle key={`${data.title}-${index}`} {...data} />
    ))}
  </BundleGroup>
);

const BundlePropTypes = {
  titleFontSize: PropTypes.number,
  title: PropTypes.string.isRequired,
  titleIcon: PropTypes.node,
  badgeProps: PropTypes.shape({ text: PropTypes.string }),
  href: PropTypes.string,
  buttonText: PropTypes.string,
  variant: PropTypes.string,
  subtext: PropTypes.arrayOf(PropTypes.string.isRequired),
  bodyComponent: PropTypes.node,
  footerComponent: PropTypes.node,
};

ArticleFooter.propTypes = {
  bundles: PropTypes.arrayOf(PropTypes.shape(BundlePropTypes)),
  bundleAndSaveExists: PropTypes.bool,
};

export default ArticleFooter;
