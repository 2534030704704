import enrichTiersWithInfinity from '@/utils/enrichTiersWithInfinity';
import { useAppSettings } from '@/components/AppSettingsProvider';
import { useApp } from '@/v2/contexts/app';

import qffHotelsTiers from '@/data/qffHotelsPointTiers';

const useLuxuryTiers = () => {
  const appSettings = useAppSettings();
  const app = useApp();

  const luxuryPointTiers =
    app?.qffPointTiers?.luxuryPointsTiers ||
    appSettings?.qffPointTiers?.luxuryPointsTiers ||
    qffHotelsTiers;

  const { tiers } = enrichTiersWithInfinity(luxuryPointTiers);

  return tiers;
};

export default useLuxuryTiers;
