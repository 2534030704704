const sortBundles = (a, b) => {
  if (a.isCompleteBundle === b.isCompleteBundle) {
    return 0;
  }

  if (a.isCompleteBundle) {
    return -1;
  }

  if (b.isCompleteBundle) {
    return 1;
  }
};

export default sortBundles;
