import PropTypes from 'prop-types';
import React from 'react';
import { Box, Text } from '@qga/roo-ui/components';
import { splitAt } from 'ramda';
import Inclusions from '@/shared/components/Inclusions';
import { Inclusion } from '@/libs/propTypes/Tile';

const Highlights = ({ inclusions, truncateFourthInclusion }) => (
  <Inclusions
    inclusions={splitAt(4, inclusions)[0]}
    truncateFourthInclusion={truncateFourthInclusion}
  />
);

Highlights.defaultProps = {
  truncateFourthInclusion: false,
};

Highlights.propTypes = {
  inclusions: PropTypes.arrayOf(PropTypes.shape(Inclusion)).isRequired,
  truncateFourthInclusion: PropTypes.bool,
};

export default Highlights;
