import { useCustomerSelection } from '@/components/CustomerSelectionProvider';
import {
  convertCashToPoints,
  convertCashToPreviousPoints,
} from '@/lib/pointsPay';
import useTiers from '@/hooks/useTiers';
import useLuxuryTiers from '@/hooks/useLuxuryTiers';
import { getLeadInPriceFromCampaignTile } from '@/utils/getLeadInPriceFromProcuredOffer';
import getHotelsLeadInPrice from '@/utils/getHotelsLeadInPrice';
import useSearchQuery from '@/libs/v2/hooks/useSearchQuery';

const getPoints = (leadInPrice, tiers) => {
  if (!leadInPrice.total?.amount)
    return { points: undefined, previousPoints: undefined };

  const points = convertCashToPoints(
    leadInPrice.total?.amount,
    tiers,
  ).toNumber();
  const previousPoints = convertCashToPreviousPoints(
    leadInPrice.total?.amount,
  ).toNumber();

  return { points, previousPoints };
};

const usePaymentBlock = (tile, isHolidaysBundle) => {
  const { procuredOffer } = tile;
  const leadInOffer = procuredOffer.rooms[0].leadInOffers[0];
  const { usePoints } = useCustomerSelection();
  const tiers = useTiers();
  const hotelsTiers = useLuxuryTiers();
  const { searchQuery } = useSearchQuery();

  const leadInPrice = isHolidaysBundle
    ? getLeadInPriceFromCampaignTile(tile, searchQuery?.originCode)
    : getHotelsLeadInPrice(leadInOffer);

  const leadInTier = isHolidaysBundle ? tiers : hotelsTiers;

  const total = leadInPrice?.total;
  const valuedAtTotal = leadInOffer.hasValuedAtPrice
    ? leadInPrice?.valuedAtTotal
    : undefined;
  const formattedLeadInPrice = { total, valuedAtTotal };

  const priceInPoints = usePoints
    ? getPoints(formattedLeadInPrice, leadInTier)
    : {};

  return {
    usePoints,
    points: priceInPoints.points,
    previousPoints: priceInPoints.previousPoints,
    total,
    valuedAtTotal,
  };
};

export default usePaymentBlock;
