import { NakedButton } from '@qga/roo-ui/components';
import styled from '@emotion/styled';
import { fontSize } from 'styled-system';
import withProps from '../withProps';

const UnderlinedNakedButton = withProps({
  textDecoration: 'underline',
})(styled(NakedButton)(fontSize));

export default UnderlinedNakedButton;
